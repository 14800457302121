import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme: { media } }) => media.md`
    flex-direction: row;
    align-items: top;
    justify-content: space-between;
  `}
`

const ProjectTitle = styled.h2`
  ${({ theme: { fontSize } }) => `
    font-size: ${fontSize?.projectTitle?.mobile}px;
  `}

  ${({ theme: { media, fontSize, pageMargins } }) => media.md`
    font-size: ${fontSize?.projectTitle?.desktop}px;
    padding-right: ${pageMargins?.xs}px;
  `}
`
const Description = styled.p`
  ${({ theme: { fontSize, lineHeight, colors } }) => `
    font-size: ${fontSize?.projectData?.description?.mobile}px;
    line-height: ${lineHeight?.projectData?.description?.mobile}px;
    color: ${colors?.darkGrey};
  `}

  ${({ theme: { media, fontSize, lineHeight, pageMargins } }) => media.md`
    width: 50%;
    font-size: ${fontSize?.projectData?.description?.desktop}px;
    line-height: ${lineHeight?.projectData?.description?.desktop}px;
    padding-right: ${pageMargins?.sm}px;
  `}
`

const DataSection = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme: { pageMargins } }) => `
    margin-top: ${pageMargins?.sm}px;
  `}

  ${({ theme: { media, pageMargins } }) => media.md`
    width: 25%;
    margin-top: 0;
    padding-right: ${pageMargins?.xs}px;
    padding-left: ${pageMargins?.xs}px;
  `}
`

const SectionTitle = styled.h4`
  ${({ theme: { colors } }) => `
    color: ${colors?.webBlue};
  `}
`

const SectionText = styled.p`
  font-weight: 300;

  ${({ theme: { colors, fontSize, lineHeight } }) => `
    color: ${colors?.midGrey};
    font-size: ${fontSize?.projectData?.details?.mobile}px;
    line-height: ${lineHeight?.projectData?.details?.mobile}px;
  `}

  ${({ theme: { media, fontSize, lineHeight } }) => media.md`
    font-size: ${fontSize?.projectData?.details?.desktop}px;
    line-height: ${lineHeight?.projectData?.details?.desktop}px;
  `}
`

const ProjectMainData = ({ projectTitle, description, details, role }) => (
  <Wrapper>
    <ProjectTitle>{projectTitle}</ProjectTitle>
    {/* <InfoContainer>
      <Description>{description}</Description>

      <DataSection>
        <SectionTitle>Details</SectionTitle>
        <SectionText>{details}</SectionText>
      </DataSection>

      <DataSection>
        <SectionTitle>Role</SectionTitle>
        <SectionText>{role}</SectionText>
      </DataSection>
    </InfoContainer> */}
  </Wrapper>
)

ProjectMainData.propTypes = {
  projectTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
}

export default ProjectMainData
