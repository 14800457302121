import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const SecondaryDescriptionWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;

  ${({ theme: { pageMargins } }) => `
    margin-top: ${pageMargins?.xs}px;
    padding: ${pageMargins?.sm}px;
  `}

  ${({ theme: { media, pageMargins } }) => media.md`
    margin: ${pageMargins?.md}px auto;
    padding: ${pageMargins?.md}px;
  `}
`
const SecondaryDescription = styled.p`
  font-weight: 300;
  text-align: center;

  ${({ theme: { fontSize, lineHeight, colors } }) => `
    color: ${colors?.midGrey};
    font-size: ${fontSize?.projectData?.secondaryDescription?.mobile}px;
    line-height: ${lineHeight?.projectData?.secondaryDescription?.mobile}px;
  `}

  ${({ theme: { media, fontSize, lineHeight } }) => media.md`
    font-size: ${fontSize?.projectData?.secondaryDescription?.desktop}px;
    line-height: ${lineHeight?.projectData?.secondaryDescription?.desktop}px;
    width: 60%;
  `}
`

const ProjectSecondaryDescription = ({ text }) => (
  <SecondaryDescriptionWrapper>
    <SecondaryDescription>{text}</SecondaryDescription>
  </SecondaryDescriptionWrapper>
)

ProjectSecondaryDescription.propTypes = {
  text: PropTypes.string.isRequired,
}

export default ProjectSecondaryDescription
