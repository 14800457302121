import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'
// import PortfolioImage from '../../atoms/PortfolioImage/PortolioImageStyle'
import Modal from '../../atoms/Modal'
import Icon from '../../atoms/Icon'
import GalleryImage from './GalleryImage'

const GalleryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
`

const ImageDisplayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
`

const ImageDisplayWrapper = styled.div`
  width: 100%;
  height: 85%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    max-height: 100%;
    min-width: 100%;
  }

  ${({ theme: { media } }) => media.md`  
    & > * {
      min-width: 85%;
      max-width: 2000px;
    }
  `}
`

const CrossIcon = styled.div`
  transform: rotate(45deg);
  position: fixed;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  z-index: 101;

  ${({ theme: { pageMargins } }) => `
    top: ${pageMargins?.sm}px;
    right: ${pageMargins?.sm}px;

    &:hover {
      filter: brightness(0.8);
    }
  `}
`

const ImageNavigationIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  position: absolute;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  z-index: 101;

  &:hover {
    filter: brightness(0.8);
  }
`

const RightIcon = styled(ImageNavigationIcon)`
  right: 0;
`

const LeftIcon = styled(ImageNavigationIcon)`
  left: 0;
  transform: rotate(180deg);
`

const Gallery = ({ images, theme }) => {
  const [selectedImage, setSelectedImage] = useState(null)

  const onPreviousImage = e => {
    e.stopPropagation()
    const currentImageIndex = images.indexOf(selectedImage)

    if (currentImageIndex === 0) {
      setSelectedImage(images[images.length - 1])
    } else {
      setSelectedImage(images[currentImageIndex - 1])
    }
  }

  const onNextImage = e => {
    e.stopPropagation()
    const currentImageIndex = images.indexOf(selectedImage)

    if (currentImageIndex === images.length - 1) {
      setSelectedImage(images[0])
    } else {
      setSelectedImage(images[currentImageIndex + 1])
    }
  }

  return (
    <GalleryWrapper>
      {images.map(image => (
        <GalleryImage
          image={image}
          onClick={img => setSelectedImage(img)}
          key={image.id}
        />
      ))}

      {selectedImage && (
        <Modal background={theme.colors.modalBlack}>
          <CrossIcon onClick={() => setSelectedImage(null)}>
            <Icon
              name="cross"
              fill={theme.colors.lightGrey}
              width={24}
              height={24}
            />
          </CrossIcon>
          <ImageDisplayContainer onClick={() => setSelectedImage(null)}>
            <LeftIcon onClick={e => onPreviousImage(e)}>
              <Icon
                name="arrowRight"
                fill={theme.colors.lightGrey}
                width={24}
                height={24}
              />
            </LeftIcon>
            <ImageDisplayWrapper>
              <Img
                fluid={selectedImage.fluidImageUrl}
                alt={selectedImage.imageTitle}
                imgStyle={{
                  objectFit: 'contain',
                  objectPosition: '50% 50%',
                }}
              />
            </ImageDisplayWrapper>
            <RightIcon onClick={e => onNextImage(e)}>
              <Icon
                name="arrowRight"
                fill={theme.colors.lightGrey}
                width={24}
                height={24}
              />
            </RightIcon>
          </ImageDisplayContainer>
        </Modal>
      )}
    </GalleryWrapper>
  )
}

Gallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      fluidImageUrl: PropTypes.object.isRequired,
      imageTitle: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  theme: PropTypes.object.isRequired,
}

export default Gallery
