import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import { graphql } from 'gatsby'
import {
  PROJECT_HEADER_HEIGHT_MOBILE,
  PROJECT_HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT,
} from '../../theme/constants'
import theme from '../../theme/theme'
import Layout from '../../components/pageLayout/Layout'
import SEO from '../../components/atoms/Seo'
import ProjectHeader from './Components/ProjectHeader'
import ProjectMainData from './Components/ProjectMainData'
import ProjectSecondaryDescription from './Components/ProjectSecondaryDescription'
import ProjectNavigation from './Components/ProjectNavigation'
import Gallery from '../../components/molecules/Gallery'

const ProjectDataWrapper = styled.div`
  margin-top: calc(${PROJECT_HEADER_HEIGHT_MOBILE}vh - ${HEADER_HEIGHT}px);
  margin-left: auto;
  margin-right: auto;

  ${({ theme: { pageMargins, maxWidths } }) => `
    padding: ${pageMargins?.sm}px;
    max-width: ${maxWidths?.content}px;
  `}

  ${({ theme: { media, pageMargins } }) => media.md`
    margin: calc(${PROJECT_HEADER_HEIGHT_DESKTOP}vh - ${HEADER_HEIGHT}px) auto auto auto;
    padding: ${pageMargins?.md}px;
  `}
`

const GalleryWrapper = styled.div`
  ${({ theme: { pageMargins } }) => `
    margin-top: ${pageMargins?.sm}px;
  `}

  ${({ theme: { media, pageMargins } }) => media.md`
    margin-top: ${pageMargins?.lg}px;
  `}
`

const PostTemplate = ({
  data: {
    allWordpressPost: { edges: allPosts },
    post: { id: currentPostId, acf: postData },
  },
}) => {
  const currentPost = allPosts.find(post => post.node.id === currentPostId)

  const addImagesArray = () => {
    const keys = Object.keys(postData)

    return keys.reduce((acc, curr) => {
      if (curr.startsWith('project_image_') && postData[curr]) {
        const image = {
          fluidImageUrl: postData[curr].localFile.childImageSharp.fluid,
          imageTitle: postData[curr].title,
          id: postData[curr].wordpress_id,
        }
        acc.push(image)
      }

      return acc
    }, [])
  }

  const postImagesArray = addImagesArray()

  return (
    <ThemeProvider theme={theme}>
      <Layout hasFooter={false}>
        <SEO title={`Work - ${postData.project_title}`} />

        <ProjectHeader
          fluidImageUrl={
            postData.project_main_image.localFile.childImageSharp.fluid
          }
          alt={postData.project_title}
        />

        <ProjectDataWrapper>
          <ProjectMainData
            projectTitle={postData.project_title}
            description={postData.project_description}
            details={postData.project_detail}
            role={postData.project_role}
          />

          <GalleryWrapper>
            <Gallery images={postImagesArray} />
          </GalleryWrapper>

          {/* {postData.project_description_secondary !== '' && (
            <ProjectSecondaryDescription
              text={postData.project_description_secondary}
            />
          )} */}
        </ProjectDataWrapper>
      </Layout>

      <ProjectNavigation
        previousProject={currentPost.previous}
        nextProject={currentPost.next}
      />
    </ThemeProvider>
  )
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PostTemplate

export const pageQuery = graphql`
  fragment FluidImage on wordpress__wp_media {
    localFile {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }

  query($id: String!) {
    allWordpressPost(sort: { fields: date, order: ASC }) {
      edges {
        previous {
          id
          link
        }
        node {
          id
        }
        next {
          id
          link
        }
      }
    }

    post: wordpressPost(id: { eq: $id }) {
      id
      acf {
        project_description
        project_description_secondary
        project_detail
        project_role
        project_title
        project_image_1 {
          title
          wordpress_id
          ...FluidImage
        }
        project_image_2 {
          title
          wordpress_id
          ...FluidImage
        }
        project_image_3 {
          title
          wordpress_id
          ...FluidImage
        }
        project_image_4 {
          title
          wordpress_id
          ...FluidImage
        }
        project_image_5 {
          title
          wordpress_id
          ...FluidImage
        }
        project_image_6 {
          title
          wordpress_id
          ...FluidImage
        }
        project_image_7 {
          title
          wordpress_id
          ...FluidImage
        }
        project_image_8 {
          title
          wordpress_id
          ...FluidImage
        }
        project_main_image {
          title
          wordpress_id
          ...FluidImage
        }
      }
    }
  }
`
