import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import Img from 'gatsby-image'
import PortfolioImage from '../../atoms/PortfolioImage/PortolioImageStyle'
import Icon from '../../atoms/Icon'
import useHover from '../../../utils/useHover'

const Image = styled(Img)`
  height: 100%;
`

const BackgroundPortfolioImage = styled(PortfolioImage)`
  height: 125px;
  cursor: pointer;

  ${({ theme: { media } }) => media.md`
    height: 400px;
  `}
`

const ImageOverlay = styled.div`
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background-clip: content-box;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;

  ${({ theme: { colors, pageMargins } }) => `
    background-color: rgba(13, 12, 20, .4);
    color: ${colors?.lightBeige};
    padding: ${pageMargins?.xs}px;
  `}
`

const hoveredStyle = {
  opacity: 1,
}

const GalleryImage = ({ image, onClick, theme }) => {
  const [ref, hovered] = useHover()

  return (
    <BackgroundPortfolioImage
      maxColumns={{ xs: 2, md: 2 }}
      key={image.id}
      onClick={() => onClick(image)}
      ref={ref}
    >
      <ImageOverlay style={hovered ? hoveredStyle : {}}>
        <Icon
          name="magnifier"
          fill={theme.colors.lightGrey}
          width={24}
          height={24}
        />
      </ImageOverlay>
      <Image fluid={image.fluidImageUrl} alt={image.imageTitle} />
    </BackgroundPortfolioImage>
  )
}

GalleryImage.propTypes = {
  image: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withTheme(GalleryImage)
