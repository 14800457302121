import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'
import {
  PROJECT_HEADER_HEIGHT_MOBILE,
  PROJECT_HEADER_HEIGHT_DESKTOP,
} from '../../../theme/constants'

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: ${PROJECT_HEADER_HEIGHT_MOBILE}vh;
  overflow: hidden;

  ${({ theme }) => theme.media.md`
    width: 100vw;
    height: ${PROJECT_HEADER_HEIGHT_DESKTOP}vh;
  `}
`

const Image = styled(Img)`
  width: 100%;
  height: 100%;
`

const ProjectHeader = ({ fluidImageUrl, alt }) => (
  <Container>
    <Image fluid={fluidImageUrl} alt={alt} />
  </Container>
)

ProjectHeader.propTypes = {
  fluidImageUrl: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
}

export default ProjectHeader
