import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { FOOTER_HEIGHT } from '../../../theme/constants'

const NavigationWrapper = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  height: ${FOOTER_HEIGHT}px;

  ${({ theme: { pageMargins, colors } }) => `
    margin-top: ${pageMargins?.sm}px;
    background: ${colors?.beige};
  `}

  ${({ theme: { media, pageMargins } }) => media.md`
    margin-top: ${pageMargins?.md}px;
  `}
`

const NavigationButton = styled.a`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  ${({ theme: { colors } }) => `
    color: ${colors?.webBlue};
  `}

  &:hover {
    ${({ theme: { colors } }) => `
      background: ${colors?.webBlue};
      color: ${colors?.lightBlue};
    `}
  }

  &:hover > * {
    ${({ theme: { colors } }) => `
      color: ${colors?.lightGrey};
    `}
  }
`

const PrevNavigationButton = styled(NavigationButton)`
  ${({ theme: { colors } }) => `
    border-top: 1.5px solid ${colors?.webBlue};
  `}

  ${({ theme: { media, colors } }) => media.md`
    border-top: 2px solid ${colors?.webBlue};
  `}

  ${({ hasPrevious, theme: { colors } }) =>
    !hasPrevious &&
    `
      pointer-events: none;
      color: ${colors?.midLightGrey};
      border-top: 1.5px solid ${colors?.midLightGrey};
  `}

  ${({ hasPrevious, theme: { media, colors } }) =>
    !hasPrevious &&
    media.md`
    border-top: 2px solid ${colors?.midLightGrey};
  `}
`

const NextNavigationButton = styled(NavigationButton)`
  ${({ theme: { colors } }) => `
    border-top: 1.5px solid ${colors?.webBlue};
  `}

  ${({ theme: { media, colors } }) => media.md`
    border-top: 2px solid ${colors?.webBlue};
  `}

  ${({ hasNext, theme: { colors } }) =>
    !hasNext &&
    `
      pointer-events: none;
      color: ${colors?.midLightGrey};
      border-top: 1.5px solid ${colors?.midLightGrey};
  `}

  ${({ hasNext, theme: { media, colors } }) =>
    !hasNext &&
    media.md`
    border-top: 2px solid ${colors?.midLightGrey};
  `}
`

const NavigationText = styled.span`
  ${({ theme: { fontSize } }) => `
    font-size: ${fontSize?.projectNavigation?.mobile}px;
  `}

  ${({ theme: { media, fontSize } }) => media.md`
    font-size: ${fontSize?.projectNavigation?.desktop}px;
  `}
`

const ProjectNavigation = ({ previousProject, nextProject }) => (
  <NavigationWrapper>
    <PrevNavigationButton
      href={`/work/project${previousProject?.link}`}
      hasPrevious={previousProject}
    >
      <NavigationText>Previous project</NavigationText>
    </PrevNavigationButton>

    <NextNavigationButton
      href={`/work/project${nextProject?.link}`}
      hasNext={nextProject}
    >
      <NavigationText>Next project</NavigationText>
    </NextNavigationButton>
  </NavigationWrapper>
)

ProjectNavigation.propTypes = {
  previousProject: PropTypes.object,
  nextProject: PropTypes.object,
}

ProjectNavigation.defaultProps = {
  previousProject: null,
  nextProject: null,
}

export default ProjectNavigation
